<template>
  <div>
    <v-skeleton-loader class="mx-auto ml-2 mr-2" type="card" :loading="loading">
      <!-- <v-container fluid class="mb-4"> -->
      <CommunityInfo
        :communityInfo="communityInfo"
        @onRelatedCommunityChanged="showRelatedCommunity"
      />
      <InternalBreadcrumbs :breadcrumbs="breadcrumbs" />
      <v-card elevation="0" outlined :loading="requestProcessing">
        <template slot="progress">
          <v-progress-linear height="10" indeterminate></v-progress-linear>
          <p class="mt-4 text-center">Processing...</p>
        </template>
        <v-card-title v-if="!requestProcessing">Quarterly Plan</v-card-title>
        <v-card-text v-if="!requestProcessing">
          <!-- Start Quarterly Plan section -->
          <v-container>
            <v-row>
              <v-col cols="6" md="3">
                <v-select
                  v-model="planQuarter"
                  :items="planQuarterItems"
                  item-text="name"
                  item-value="value"
                  label="Select Quarter..."
                  required
                ></v-select>
              </v-col>
              <v-col cols="6" md="3">
                <v-select
                  v-model="planYear"
                  :items="planYearItems"
                  item-text="name"
                  item-value="value"
                  label="Select Year..."
                  required
                ></v-select>
              </v-col>
            </v-row>
            <!-- <v-row>
              <v-col>
                <v-menu
                  ref="startDateMenu"
                  v-model="startDateMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="formattedStartDate"
                      label="Start Date*"
                      prepend-icon="mdi-calendar"
                      v-bind="attrs"
                      @blur="startDate = parseDate(formattedStartDate)"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="startDate"
                    no-title
                    @input="startDateMenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row> -->
            <v-row>
              <v-col md="4" cols="12">
                <v-progress-linear
                  color="blue"
                  :value="plan.progress"
                  height="25"
                >
                  <template v-slot:default="{ value }">
                    <strong>{{ Math.ceil(value) }}%</strong>
                  </template></v-progress-linear
                >
              </v-col>
              <v-col md="4" sm="6" cols="12">
                <span class="v-label">
                  Quarterly Orange Walk is scheduled for
                  {{ formattedQuarterlyOrangeWalkDate }}
                </span>
              </v-col>
              <v-col md="4" sm="6" cols="12">
                <v-menu
                  ref="quarterlyOrangeWalkDateMenu"
                  v-model="quarterlyOrangeWalkDateMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="formattedQuarterlyOrangeWalkDate"
                      label="Quarterly Orange Walk Date*"
                      prepend-icon="mdi-calendar"
                      v-bind="attrs"
                      @blur="
                        quarterlyOrangeWalkDate = parseDate(
                          formattedQuarterlyOrangeWalkDate
                        )
                      "
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="quarterlyOrangeWalkDate"
                    no-title
                    @input="quarterlyOrangeWalkDateMenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <p>
              Add each task individually for each 30 day period. Drag and drop
              any individual task to reorder.
            </p>
            <v-row>
              <v-col cols="12" md="8">
                <hr />
                <h6>{{ month1Name }}</h6>
                <div>
                  <v-card class="tasksArea" elevation="0" outlined>
                    <v-card-title>
                      <v-btn
                        x-small
                        v-if="!tasks30AddInProcess"
                        @click="tasks30AddInProcess = true"
                      >
                        + Add Task
                      </v-btn>
                    </v-card-title>
                    <v-card-text>
                      <div v-if="tasks30AddInProcess">
                        <v-textarea
                          v-model="taskContent"
                          solo
                          dense
                        ></v-textarea>
                        <v-btn x-small @click="addTaskToPlan(30)">
                          Add to Plan
                        </v-btn>
                      </div>
                      <table width="100%" class="mt-2">
                        <tr
                          v-for="(item, i) in tasksFor30Days"
                          :key="'tr30' + i"
                        >
                          <td width="80%">
                            <p v-if="!item.editInProcess">
                              {{ item.description }}
                            </p>
                            <v-textarea
                              v-if="item.editInProcess"
                              v-model="item.descriptionCopy"
                              solo
                              dense
                            ></v-textarea>
                          </td>
                          <td width="20%">
                            <v-btn
                              x-small
                              v-if="!item.editInProcess"
                              @click="startEdit(item)"
                            >
                              Edit </v-btn
                            ><v-btn
                              x-small
                              v-if="!item.editInProcess"
                              @click="deleteItem(30, i)"
                            >
                              Delete
                            </v-btn>
                            <v-btn
                              x-small
                              v-if="item.editInProcess"
                              @click="cancelEdit(item)"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              x-small
                              v-if="item.editInProcess"
                              @click="confirmEdit(item)"
                            >
                              Save
                            </v-btn>
                          </td>
                        </tr>
                      </table>
                    </v-card-text>
                  </v-card>
                  <h6 class="mt-2">{{ month1Name }} Notes</h6>
                  <v-textarea v-model="plan.day30" solo></v-textarea>
                </div>
                <hr />
                <h6>{{ month2Name }}</h6>
                <div>
                  <v-card class="tasksArea" elevation="0" outlined>
                    <v-card-title>
                      <v-btn
                        x-small
                        v-if="!tasks60AddInProcess"
                        @click="tasks60AddInProcess = true"
                      >
                        + Add Task
                      </v-btn>
                    </v-card-title>
                    <v-card-text>
                      <div v-if="tasks60AddInProcess">
                        <v-textarea
                          v-model="taskContent"
                          solo
                          dense
                        ></v-textarea>
                        <v-btn x-small @click="addTaskToPlan(60)">
                          Add to Plan
                        </v-btn>
                      </div>
                      <table width="100%" class="mt-2">
                        <tr
                          v-for="(item, i) in tasksFor60Days"
                          :key="'tr60' + i"
                        >
                          <td width="80%">
                            <p v-if="!item.editInProcess">
                              {{ item.description }}
                            </p>
                            <v-textarea
                              v-if="item.editInProcess"
                              v-model="item.descriptionCopy"
                              solo
                              dense
                            ></v-textarea>
                          </td>
                          <td width="20%">
                            <v-btn
                              x-small
                              v-if="!item.editInProcess"
                              @click="startEdit(item)"
                            >
                              Edit </v-btn
                            ><v-btn
                              x-small
                              v-if="!item.editInProcess"
                              @click="deleteItem(60, i)"
                            >
                              Delete
                            </v-btn>
                            <v-btn
                              x-small
                              v-if="item.editInProcess"
                              @click="cancelEdit(item)"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              x-small
                              v-if="item.editInProcess"
                              @click="confirmEdit(item)"
                            >
                              Save
                            </v-btn>
                          </td>
                        </tr>
                      </table>
                    </v-card-text>
                  </v-card>
                  <h6 class="mt-2">{{ month2Name }} Notes</h6>
                  <v-textarea v-model="plan.day60" solo></v-textarea>
                </div>
                <hr />
                <h6>{{ month3Name }}</h6>
                <div>
                  <v-card class="tasksArea" elevation="0" outlined>
                    <v-card-title>
                      <v-btn
                        x-small
                        v-if="!tasks90AddInProcess"
                        @click="tasks90AddInProcess = true"
                      >
                        + Add Task
                      </v-btn>
                    </v-card-title>
                    <v-card-text>
                      <div v-if="tasks90AddInProcess">
                        <v-textarea
                          v-model="taskContent"
                          solo
                          dense
                        ></v-textarea>
                        <v-btn x-small @click="addTaskToPlan(90)">
                          Add to Plan
                        </v-btn>
                      </div>
                      <table width="100%" class="mt-2">
                        <tr
                          v-for="(item, i) in tasksFor90Days"
                          :key="'tr90' + i"
                        >
                          <td width="80%">
                            <p v-if="!item.editInProcess">
                              {{ item.description }}
                            </p>
                            <v-textarea
                              v-if="item.editInProcess"
                              v-model="item.descriptionCopy"
                              solo
                              dense
                            ></v-textarea>
                          </td>
                          <td width="20%">
                            <v-btn
                              x-small
                              v-if="!item.editInProcess"
                              @click="startEdit(item)"
                            >
                              Edit
                            </v-btn>
                            <v-btn
                              x-small
                              v-if="!item.editInProcess"
                              @click="deleteItem(90, i)"
                            >
                              Delete
                            </v-btn>
                            <v-btn
                              x-small
                              v-if="item.editInProcess"
                              @click="cancelEdit(item)"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              x-small
                              v-if="item.editInProcess"
                              @click="confirmEdit(item)"
                            >
                              Save
                            </v-btn>
                          </td>
                        </tr>
                      </table>
                    </v-card-text>
                  </v-card>
                  <h6 class="mt-2">{{ month3Name }} Notes</h6>
                  <v-textarea v-model="plan.day90" solo></v-textarea>
                </div>
              </v-col>
              <v-col cols="12" md="4">
                <v-row>
                  <v-col class="px-4 pb-n4" cols="12">
                    <span class="v-label theme--light text-uppercase"
                      >Upload Photos</span
                    >
                    <v-col
                      v-for="image in photosObj"
                      cols="12"
                      class="darggable-col"
                      :key="image.id"
                    >
                      <MediaUploader
                        class="pb-5"
                        :key="image.id"
                        :id="`multiImagePost_${image.id}`"
                        :index="image.id"
                        :accept="accept"
                        :multiple="true"
                        :extensions="extensions"
                        :sizeImage="10485760"
                        :sizeVideo="104857600"
                        :fileId.sync="image.fileId"
                        :file-url.sync="image.url"
                        :isUploading.sync="isFileUploading"
                        @onMultipleUploaded="onMultipleAddFile"
                        @onChange="onChange"
                        @onDeleteFile="onDeleteFile"
                      />
                    </v-col>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
          <!-- End Quarterly Plan section -->
        </v-card-text>
        <v-card-actions v-if="!requestProcessing">
          <v-row>
            <v-col cols="12" md="9" class="text-right">
              <v-btn color="primary" class="mx-1" @click="saveNintyDayPlan"
                >Save Quarterly Plan</v-btn
              >

              <router-link
                class="blue--text mx-1"
                :to="`/customers/${customerId}/plans/${planId}/view`"
              >
                <v-btn>Cancel</v-btn>
              </router-link>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>

      <!-- </v-container> -->
    </v-skeleton-loader>
  </div>
</template>

<script>
import CommunityInfo from "@/view/components/CommunityInfo.vue";
import InternalBreadcrumbs from "@/view/components/DashboardInternalBreadcrumbs.vue";
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";
import {
  GET_QUERY,
  API_CUSTOMERS,
  POST_DATA,
  PUT_DATA,
  API_QUARTERLY_PLAN_TEMPLATES
} from "@/core/store/crud.module";
import crud from "@/core/helpers/crud";
// import dateFormatter from "@/core/helpers/dateFormatter";
import stringFormatter from "@/core/helpers/stringFormatter";
import { validationMixin } from "vuelidate";
// import dataModels from "@/core/helpers/datamodels.js";
// import { required } from "vuelidate/lib/validators";
// import moment from "moment";
import MediaUploader from "@/view/components/imageUploader/mediaUploader.vue";

export default {
  mixins: [validationMixin],
  components: {
    CommunityInfo,
    InternalBreadcrumbs,
    MediaUploader
  },
  data: () => ({
    communityInfo: {},
    plan: {
      startDate: null,
      orangeWalkDate: null,
      formattedQuarterlyOrangeWalkDate: null,
      proress: 0,
      day30: "",
      day60: "",
      day90: "",
      photos: []
    },
    planQuarter: 1,
    planQuarterItems: [
      { name: "Q1", value: "1" },
      { name: "Q2", value: "2" },
      { name: "Q3", value: "3" },
      { name: "Q4", value: "4" }
    ],
    tasks30AddInProcess: false,
    tasks60AddInProcess: false,
    tasks90AddInProcess: false,
    taskContent: "",
    tasksFor30Days: [],
    tasksFor60Days: [],
    tasksFor90Days: [],
    planYear: 1,
    planYearItems: [],
    currentDayPlan: {},
    photosObj: [{ id: 1, url: null }],
    isFileUploading: false,
    videoExtensions: "mp4,mov,avi,mkv,mpg,vob,wmv,m4v,asf,srt",
    relatedCommunity: null,
    relatedCommunityItems: [],
    startDate: null,
    formattedStartDate: null,
    startDateMenu: false,
    quarterlyOrangeWalkDate: null,
    formattedQuarterlyOrangeWalkDate: null,
    quarterlyOrangeWalkDateMenu: false,
    options: {
      page: 1,
      itemsPerPage: 5
    },
    serviceAlert: {},
    pageText: "",
    startingAfter: "",
    endingBefore: "",
    previousPage: 1,
    hasMore: false,
    isLoading: false,
    loading: false,
    snackbarContent: "",
    snackbarColor: "#F06835",
    breadcrumbs: [],
    requestProcessing: false,
    month1Name: "30 Days",
    month2Name: "60 Days",
    month3Name: "90 Days"
  }),
  props: {
    customerId: {
      type: [Number, String],
      default: 0
    },
    planId: {
      type: [Number, String],
      default: 0
    }
  },
  validations() {
    return true;
    // return {
    //   billingInfo: {
    //     subscription: {
    //       nextBillingDates: {
    //         required,
    //         minDate: value =>
    //           dateFormatter.convertToMilliseconds(value) >
    //           dateFormatter.convertToMilliseconds(new Date())
    //       }
    //     }
    //   }
    // };
  },
  watch: {
    quarterlyOrangeWalkDate: function() {
      this.formattedQuarterlyOrangeWalkDate = this.formatDate(
        this.quarterlyOrangeWalkDate
      );
    },
    startDate: function() {
      this.formattedStartDate = this.formatDate(this.startDate);
    },
    planQuarter: function() {
      if (this.planQuarter == "1") {
        this.month1Name = "January";
        this.month2Name = "February";
        this.month3Name = "March";
      } else if (this.planQuarter == "2") {
        this.month1Name = "April";
        this.month2Name = "May";
        this.month3Name = "June";
      } else if (this.planQuarter == "3") {
        this.month1Name = "July";
        this.month2Name = "August";
        this.month3Name = "September";
      } else if (this.planQuarter == "4") {
        this.month1Name = "October";
        this.month2Name = "November";
        this.month3Name = "December";
      }
    }
  },
  async mounted() {
    // this.loading = true;
    // await this.getComunityInfo();
    // await this.createBreadcrumb();
    // await this.setInternalBreadcrumbs();
    // this.loading = false;
    this.populateYearSelect();
  },
  async created() {
    await this.getComunityInfo();
    if (this.$route.query.sourcePlanId || this.$route.query.sourceTemplateId)
      await this.getNinetyDayPlan();
    this.startDate = new Date().toISOString().substr(0, 10);
    this.formattedStartDate = this.formatDate(this.startDate);
  },
  methods: {
    startEdit(item) {
      item.editInProcess = true;
      item.descriptionCopy = item.description;
    },
    deleteItem(days, index) {
      let array = this.tasksFor30Days;
      if (days === 30) {
        array = this.tasksFor30Days;
      }

      if (days === 60) {
        array = this.tasksFor60Days;
      }

      if (days === 90) {
        array = this.tasksFor90Days;
      }

      array.splice(index, 1);
    },
    cancelEdit(item) {
      item.editInProcess = false;
      item.descriptionCopy = "";
    },
    confirmEdit(item) {
      item.editInProcess = false;
      item.description = item.descriptionCopy;
    },
    addTaskToPlan(days) {
      let arrayToAdd = this.tasksFor30Days;
      if (days === 30) {
        arrayToAdd = this.tasksFor30Days;
        this.tasks30AddInProcess = false;
      }

      if (days === 60) {
        arrayToAdd = this.tasksFor60Days;
        this.tasks60AddInProcess = false;
      }

      if (days === 90) {
        arrayToAdd = this.tasksFor90Days;
        this.tasks90AddInProcess = false;
      }
      let tasksCount = arrayToAdd.length;
      arrayToAdd.push({
        description: this.taskContent,
        id: -tasksCount,
        editInProcess: false,
        descriptionCopy: ""
      });

      this.taskContent = "";
    },
    populateYearSelect() {
      var currentDate = new Date();
      var currentYear = currentDate.getFullYear();

      for (let i = 0; i < 10; i++) {
        this.planYearItems.push({
          name: currentYear + i,
          value: currentYear + i
        });
      }
    },
    showRelatedCommunity(communityId) {
      this.$router.push({
        name: "CustomerDetails",
        params: { customerId: communityId }
      });
    },
    async getComunityInfo() {
      this.loading = true;

      this.isLoading = true;

      await this.$store
        .dispatch(GET_QUERY, {
          listName: API_CUSTOMERS,
          id: this.customerId
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            this.communityInfo = crud.getPopulatedComunityInfo(response.data);
            this.createBreadcrumb();
            this.setInternalBreadcrumbs();

            this.plan = this.communityInfo.ninetyDayPlans[
              this.communityInfo.ninetyDayPlans.length - 1
            ] || {
              progress: 0,
              startDate: new Date().toISOString().substr(0, 10),
              orangeWalkDate: new Date().toISOString().substr(0, 10),
              day30: "",
              day60: "",
              day90: "",
              photos: []
            };
          } else {
            this.$snackbar.showMessage({
              content: response.data || "Something went wrong!",
              color: "red"
            });
          }

          this.loading = false;
          this.isLoading = false;
        });
    },
    async getNinetyDayPlan() {
      this.loading = true;

      this.isLoading = true;
      let url = `${API_CUSTOMERS}/${this.customerId}/plans/current`;

      if (this.planId > 0)
        url = `${API_CUSTOMERS}/${this.customerId}/plans/${this.planId}`;

      if (this.$route.query.sourcePlanId)
        url = `${API_CUSTOMERS}/${this.customerId}/plans/${this.$route.query.sourcePlanId}`;

      if (this.$route.query.sourceTemplateId)
        url = `${API_QUARTERLY_PLAN_TEMPLATES}/${this.$route.query.sourceTemplateId}`;

      await this.$store
        .dispatch(GET_QUERY, {
          listName: url
        })
        .then(response => {
          this.plan = {
            progress: 0,
            startDate: new Date().toISOString().substr(0, 10),
            orangeWalkDate: new Date().toISOString().substr(0, 10),
            day30: "",
            day60: "",
            day90: "",
            photos: []
          };
          if (response.status >= 200 || response.status <= 204) {
            this.plan = response.data;
            if (this.plan) {
              this.plan.progress = 0;
              this.plan.startDate = new Date().toISOString().substr(0, 10);

              if (this.plan.quarter) this.planQuarter = "" + this.plan.quarter;
              // console.log(this.planQuarter );

              if (this.plan.photos)
                this.populatePhotosObj(this.plan.photos.$values);
              else this.plan.photos = [];

              if (this.plan.orangeWalkDate) {
                this.quarterlyOrangeWalkDate = new Date(
                  this.plan.orangeWalkDate
                )
                  .toISOString()
                  .substr(0, 10);
              } else {
                this.plan.orangeWalkDate = new Date()
                  .toISOString()
                  .substr(0, 10);
              }
              this.formattedQuarterlyOrangeWalkDate = this.formatDate(
                this.quarterlyOrangeWalkDate
              );

              this.tasksFor30Days = this.plan.day30Tasks.$values.map(i => {
                return {
                  id: 0,
                  description: i.description,
                  weight: 1,
                  status: 0,
                  editInProcess: false,
                  descriptionCopy: ""
                };
              });

              this.tasksFor60Days = this.plan.day60Tasks.$values.map(i => {
                return {
                  id: 0,
                  description: i.description,
                  weight: 1,
                  status: 0,
                  editInProcess: false,
                  descriptionCopy: ""
                };
              });

              this.tasksFor90Days = this.plan.day90Tasks.$values.map(i => {
                return {
                  id: 0,
                  description: i.description,
                  weight: 1,
                  status: 0,
                  editInProcess: false,
                  descriptionCopy: ""
                };
              });
            }
          } else {
            this.$snackbar.showMessage({
              content: response.data || "Something went wrong!",
              color: "red"
            });
          }

          this.loading = false;
          this.isLoading = false;
        });
    },

    populatePhotosObj(photos) {
      this.photosObj = photos.map(obj => {
        return { id: obj.id, url: obj.fileUrl, fileId: obj.id };
      });
      let max = Math.max(...this.photosObj.map(i => i.id));
      this.photosObj.push({ id: (max += 1), url: null });
    },
    async setInternalBreadcrumbs() {
      this.breadcrumbs = [
        {
          title: this.communityInfo.name,
          route: "/customers/" + this.customerId
        },
        {
          title: "Quarterly Plans",
          route: "/customers/" + this.customerId + "/plans"
        },
        { title: "Edit/New Quarterly Plan" }
      ];
    },
    createBreadcrumb() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Customers", route: "/customers" },
        {
          title: this.communityInfo.name,
          route: "/customers/" + this.customerId
        },
        {
          title: "Quarterly Plans",
          route: "/customers/" + this.customerId + "/plans"
        },
        { title: "Edit/New Quarterly Plan" }
      ]);
    },
    saveNintyDayPlan() {
      this.plan.customerId = this.customerId;
      // this.plan.startDate = moment.utc(this.startDate);
      // this.plan.endDate = moment.utc(this.startDate).add(90, "days");
      this.plan.orangeWalkDate = this.quarterlyOrangeWalkDate;
      this.plan.progress = 0; //Math.ceil(this.plan.progress);
      this.plan.isActive = true;
      this.plan.title = "Q" + this.planQuarter + " " + this.planYear;

      if (this.planQuarter === "1") {
        this.plan.startDate = new Date(Date.UTC(this.planYear, 0, 1));
        this.plan.endDate = new Date(Date.UTC(this.planYear, 2, 31));
      }
      if (this.planQuarter === "2") {
        this.plan.startDate = new Date(Date.UTC(this.planYear, 3, 1));
        this.plan.endDate = new Date(Date.UTC(this.planYear, 5, 31));
      }
      if (this.planQuarter === "3") {
        this.plan.startDate = new Date(Date.UTC(this.planYear, 6, 1));
        this.plan.endDate = new Date(Date.UTC(this.planYear, 8, 30));
      }
      if (this.planQuarter === "4") {
        this.plan.startDate = new Date(Date.UTC(this.planYear, 9, 1));
        this.plan.endDate = new Date(Date.UTC(this.planYear, 11, 31));
      }

      var self = this;

      this.plan.day30Tasks = this.tasksFor30Days.map(i => {
        return { id: 0, description: i.description, weight: 1, status: 0 };
      });

      this.plan.day60Tasks = this.tasksFor60Days.map(i => {
        return { id: 0, description: i.description, weight: 1, status: 0 };
      });

      this.plan.day90Tasks = this.tasksFor90Days.map(i => {
        return { id: 0, description: i.description, weight: 1, status: 0 };
      });

      this.plan.photos = this.photosObj
        .filter(
          i =>
            i.url &&
            i.url.length > 0 &&
            !self.videoExtensions.includes(self.getExtensionFromUrl(i.url))
        )
        .map(i => {
          return { id: i.fileId, fileUrl: i.url };
        });
      this.plan.photos = this.plan.photos.filter(
        (item, index) => this.plan.photos.indexOf(item) === index
      ); //remove duplicates

      let url = `${API_CUSTOMERS}/${this.customerId}/plans`;
      let action = POST_DATA;
      if (this.planId) {
        url = `${API_CUSTOMERS}/${this.customerId}/plans/${this.planId}`;
        action = PUT_DATA;
      }

      this.requestProcessing = true;

      this.$store
        .dispatch(action, {
          listName: url,
          data: this.plan
        })
        .then(response => {
          if (response) {
            self.snackbarContent = "Quarterly Plan has been saved successfully";
            self.$router.push({
              name: "NinetyDayPlans",
              params: { customerId: self.customerId }
            });
          } else {
            self.snackbarContent = "Something went wrong!"; //response.data;
            self.snackbarColor = "red";
          }
          self.$snackbar.showMessage({
            content: self.snackbarContent,
            color: self.snackbarColor
          });

          self.confirmDialog = self;
          self.requestProcessing = false;
        })
        .catch(err => {
          self.$snackbar.showMessage({
            content: err,
            color: self.snackbarColor
          });
          self.requestProcessing = false;
        });
    },
    onMultipleAddFile(files) {
      for (var i = 1; i < files.length; i++) {
        let max = Math.max(...this.photosObj.map(i => i.id));
        this.photosObj.push({
          id: (max += 1),
          url: files[i].fileUrl,
          fileId: files[i].id
        });
      }
      let max = Math.max(...this.photosObj.map(i => i.id));
      this.photosObj.push({ id: (max += 1), url: null });
      this.$nextTick(() => files.forEach(f => this.onChange(f)));
    },
    onChange(file) {
      if (file.fileUrl) {
        var found = this.photosObj.find(f => f.url == file.fileUrl);
        found.fileId = file.id;

        var extension = this.getExtensionFromUrl(file.fileUrl);
        var isVideo = this.videoExtensions.includes(extension);
        if (isVideo) {
          var videoInStore = 0;
          this.photosObj.forEach(obj => {
            var ext = this.getExtensionFromUrl(obj.url);
            var isVideo = this.videoExtensions.includes(ext);
            if (isVideo) videoInStore += 1;
          });
          if (videoInStore > 1) {
            let found = this.photosObj.find(f => f.url == file.fileUrl);
            let index = this.photosObj.indexOf(found);
            this.photosObj.splice(index, 1);
            this.saveResult.text = "You can add only one video";
            this.showModal = true;
          }
        }
      }
    },
    onDeleteFile(fileData) {
      let index = this.photosObj.findIndex(f => f.id === fileData.index);
      this.photosObj.splice(index, 1);
    },
    onDragCallBack(evt) {
      return (
        evt.draggedContext.element.url != null &&
        evt.relatedContext.element.url != null
      );
    },
    getExtensionFromUrl(url) {
      if (url) {
        var splited = url.split(".");
        return splited.length > 1 ? splited[splited.length - 1] : null;
      }
      return null;
    },
    async submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
    },
    capitalizeFirstLetter(string) {
      return stringFormatter.capitalizeFirstLetter(string);
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    }
  },
  computed: {
    accept() {
      return "image/png,image/gif,image/jpeg,image/webp,video/mp4,video/*";
    },
    extensions() {
      return "gif,jpg,jpeg,png,webp,mp4,mov,avi,mkv,mpg,vob,wmv,m4v,asf,srt";
    }
  }
};
</script>
